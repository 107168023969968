<template>
  <div class="create">
    <div class="timeline">
      <dw-time-line
        :styleType="'2'"
        :data="stepList"
        :status="step"
        class="dwTimeLine"
      ></dw-time-line>
    </div>
    <div class="stepDiv step1">
      <div class="regula30 step">Step.{{ step + 1 }}</div>
      <div v-if="step == 3" @click="Review()" class="skip">
        {{ $t("lang.BText121") }}
      </div>
      <el-form :model="form0" :rules="rules" ref="form0" v-if="step == 0">
        <div class="step-title">{{ $t("lang.QText9") }}</div>
        <div class="dw-card2">
          <div class="title">{{ $t("lang.QText13") }}</div>
          <div class="main">
            <el-form-item class="form-item radio" prop="fromType">
              <el-radio
                v-model="form0.fromType"
                label="0"
                @change="changeFormType"
              >
                CY
              </el-radio>
              <el-radio
                v-model="form0.fromType"
                label="1"
                @change="changeFormType"
              >
                Door
              </el-radio>
            </el-form-item>
            <el-form-item
              class="form-item"
              prop="fromCyPort"
              v-if="form0.fromType == 0"
              :label="$t('lang.NText177')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.NText100'),
                },
              ]"
            >
              <el-select
                class="step1-select"
                v-model="form0.fromCyPort"
                :placeholder="$t('lang.BText80')"
                filterable
                remote
                clearable
                :remote-method="findPortsList"
                :loading="selectLoading"
                ref="fromCyPort"
              >
                <el-option
                  v-for="item in PortsList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="form0.fromType == 1">
              <el-form-item
                class="form-item"
                prop="fromDoorAddress"
                :label="$t('lang.NText178')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText102'),
                  },
                ]"
              >
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover">
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li v-for="(item,index) in recentlyData" :key="index" @click="chooseRecently(item)">
                        <span v-show="item.address">{{ item.address }}, </span>
                        <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                        <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                        <span v-show="item.countryShow">{{
                          item.countryShow
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    :placeholder="$t('lang.BText41')"
                    v-model="form0.fromDoorAddress"
                    resize="none"
                    maxlength="100"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover">
                  <ul class="RecentlyUl">
                    <li v-for="(item,index) in recentlyData" :key="index" @click="chooseRecently(item)">
                      <span v-show="item.address">{{ item.address }}, </span>
                      <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                      <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                      <span v-show="item.countryShow">{{
                        item.countryShow
                      }}</span>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="fromDoorCountry"
                  :label="$t('lang.QText52')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText161'),
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form0.fromDoorCountry"
                    :placeholder="$t('lang.BText80')"
                    @change="countryChange($event, 0)"
                    filterable
                    ref="fromDoorCountry"
                  >
                    <el-option
                      v-for="item in countryList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  class="form-item"
                  prop="fromDoorState"
                  :label="$t('lang.QText53')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText160'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form0.fromDoorState"
                    :placeholder="$t('lang.BText80')"
                    @change="stateChange($event, 0)"
                    filterable
                    :loading="fromDoorStateLoading"
                    ref="fromDoorState"
                  >
                    <el-option
                      v-for="item in fromDoorStateList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="fromDoorCity"
                  :label="$t('lang.QText54')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText159'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form0.fromDoorCity"
                    :placeholder="$t('lang.BText80')"
                    filterable
                    :loading="fromDoorCityLoading"
                    ref="fromDoorCity"
                  >
                    <el-option
                      v-for="item in fromDoorCityList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="fromDoorZipCode"
                  :label="$t('lang.QText55')"
                >
                  <!-- <div class="form-item-title">Zip code</div> -->
                  <el-input v-model="form0.fromDoorZipCode"> </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <div class="dw-card2">
          <div class="title">{{ $t("lang.QText14") }}</div>
          <div class="main">
            <el-form-item class="form-item radio" prop="toType">
              <el-radio v-model="form0.toType" label="0" @change="changeToType">
                CY
              </el-radio>
              <el-radio v-model="form0.toType" label="1" @change="changeToType">
                Door
              </el-radio>
            </el-form-item>
            <el-form-item
              class="form-item"
              prop="toCyPort"
              v-if="form0.toType == 0"
              :label="$t('lang.QText18')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.NText179'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                class="step1-select"
                v-model="form0.toCyPort"
                :placeholder="$t('lang.BText80')"
                filterable
                remote
                clearable
                :remote-method="findPortsList"
                :loading="selectLoading"
                ref="toCyPort"
              >
                <el-option
                  v-for="item in PortsList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="form0.toType == 1">
              <el-form-item
                class="form-item"
                prop="toDoorAddress"
                :label="$t('lang.NText180')"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.SText157'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-popover
                  placement="bottom"
                  popper-class="Recentlypopover"
                  trigger="hover">
                  <div class="Recentlyused">
                    <p>最近常用</p>
                    <ul>
                      <li v-for="(item,index) in recentlyDataDe" :key="index" @click="chooseRecentlyDe(item)">
                        <span v-show="item.address">{{ item.address }}, </span>
                        <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                        <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                        <span v-show="item.countryShow">{{
                          item.countryShow
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    :placeholder="$t('lang.BText41')"
                    v-model="form0.toDoorAddress"
                    resize="none"
                    maxlength="100"
                    slot="reference"
                  >
                  </el-input>
                </el-popover>
                <el-popover
                  placement="left"
                  popper-class="RecentlypopoverRight"
                  trigger="hover">
                  <ul class="RecentlyUl">
                    <li v-for="(item,index) in recentlyDataDe" :key="index" @click="chooseRecentlyDe(item)">
                      <span v-show="item.address">{{ item.address }}, </span>
                      <span v-show="item.cityShow">{{ item.cityShow }}, </span>
                      <span v-show="item.stateShow">{{ item.stateShow }}, </span>
                      <span v-show="item.countryShow">{{
                        item.countryShow
                      }}</span>
                    </li>
                  </ul>
                  <i class="el-icon-more more" slot="reference"></i>
                </el-popover>
              </el-form-item>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="toDoorCountry"
                  :label="$t('lang.QText52')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText161'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form0.toDoorCountry"
                    :placeholder="$t('lang.BText80')"
                    @change="countryChange($event, 1)"
                    filterable
                    ref="toDoorCountry"
                  >
                    <el-option
                      v-for="item in countryList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="toDoorState"
                  :label="$t('lang.QText53')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText160'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form0.toDoorState"
                    :placeholder="$t('lang.BText80')"
                    @change="stateChange($event, 1)"
                    filterable
                    :loading="toDoorStateLoading"
                    ref="toDoorState"
                  >
                    <el-option
                      v-for="item in toDoorStateList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="toDoorCity"
                  :label="$t('lang.QText54')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.SText159'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form0.toDoorCity"
                    :placeholder="$t('lang.BText80')"
                    filterable
                    :loading="toDoorCityLoading"
                    ref="toDoorCity"
                  >
                    <el-option
                      v-for="item in toDoorCityList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  class="form-item"
                  prop="toDoorZipCode"
                  :label="$t('lang.QText55')"
                >
                  <el-input v-model="form0.toDoorZipCode"> </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form>

      <el-form v-if="step == 1" :model="form1" :rules="rules" ref="form1">
        <div class="step-title">{{ $t("lang.QText10") }}</div>
        <div class="dw-card2">
          <div class="title">{{ $t("lang.QText21") }}</div>
          <div class="main">
            <div class="form-line">
              <el-form-item
                class="form-item"
                prop="cargoReadyDate"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText101'),
                    trigger: 'blur',
                  },
                ]"
                :label="$t('lang.QText21')"
              >
                <el-date-picker
                  v-model="form1.cargoReadyDate"
                  type="date"
                  :placeholder="$t('lang.QText23')"
                  :picker-options="pickerOptions"
                  @change="CargoReadyDayChange"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="dw-card2">
          <div class="title">{{ $t("lang.BText28") }}</div>
          <div class="main">
            <div class="form-line">
              <el-form-item
                class="form-item"
                prop="deliveryDate"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText107'),
                    trigger: 'blur',
                  },
                ]"
                :label="$t('lang.BText28')"
              >
                <el-date-picker
                  v-model="form1.deliveryDate"
                  type="date"
                  :placeholder="$t('lang.QText23')"
                  :picker-options="deliveryDayOptions"
                  @change="deliveryDayChange"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>

      <el-form v-if="step == 2" :model="form2" :rules="rules" ref="form2">
        <div class="step-title">{{ $t("lang.BText16") }}</div>
        <div class="dw-card2">
          <div class="title">{{ $t("lang.BText82") }}</div>
          <div class="main">
            <div class="ContainersTitle">* {{ $t("lang.BText82") }}</div>
            <div v-for="(domain, index) in form2.weightList" :key="index">
              <el-form-item
                :prop="`weightList.${index}.containerSize`"
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText96'),
                    trigger: 'blur',
                  },
                ]"
                class="weightItem"
              >
                <el-select
                  class="weight-select"
                  v-model="domain.containerSize"
                  placeholder=""
                  @change="weightSelectChange"
                >
                  <el-option
                    v-for="item in ContainerTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
                <el-input
                  class="weightInput"
                  v-model="domain.count"
                  min="1"
                  max="99"
                  :maxlength="2"
                  @input="domain.count = domain.count.replace(/[^\d]/g, '')"
                >
                  <div slot="prefix" class="chenghao">X</div>
                </el-input>
                <div class="add" @click="Add(index)">
                  <svg-icon icon-class="up"></svg-icon>
                </div>
                <div class="prep" @click="Prep(index)">
                  <svg-icon icon-class="down"></svg-icon>
                </div>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click.prevent="removeDomain(domain)"
                  v-if="form2.weightList.length > 1"
                ></el-button>
                <el-button
                  v-if="
                    index == form2.weightList.length - 1 &&
                    index < ContainerTypeList.length - 1
                  "
                  type="text"
                  @click.prevent="addDomain"
                  >{{ $t("lang.BText83") }}</el-button
                >
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="dw-card2">
          <div class="title">{{ $t("lang.BText104") }}</div>
          <div class="main">
            <div class="form-line">
              <el-form-item
                class="form-item"
                prop="cargoType"
                :label="$t('lang.QText43')"
              >
                <el-select
                  class="date-card-select"
                  v-model="form2.cargoType"
                  :placeholder="$t('lang.BText80')"
                  ref="cargoType"
                >
                  <el-option
                    v-for="item in CargoTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-line">
              <el-form-item
                class="form-item"
                prop="commodityHscodes"
                :label="$t('lang.QText47')"
              >
                <el-select
                  class="date-card-select"
                  v-model="form2.commodityHscodes"
                  :placeholder="$t('lang.BText80')"
                  filterable
                  ref="commodityHscodes"
                >
                  <el-option
                    v-for="item in CommodityType"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item
              class="form-item unspin"
              prop="totalWeight"
              :label="$t('lang.QText28')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.SText136'),
                },

                {
                  pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
                  message: $t('lang.QText96'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                class="input48"
                v-model="form2.totalWeight"
                type="number"
                @keydown.native="limite2"
                min="0"
                maxlength="10"
                ><template slot="append"
                  ><el-select
                    class="Unit-select"
                    v-model="form2.totalWeightUnit"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in WeightUnitList"
                      :key="item.value"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :disabled="item.disabled"
                    >
                    </el-option> </el-select
                ></template>
              </el-input>
            </el-form-item>
            <el-form-item
              class="form-item unspin"
              prop="totalVolume"
              :label="$t('lang.QText29')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.SText135'),
                },
                {
                  pattern: /^[0-9]{0,7}(\.[0-9]{1,3})?$/,
                  message: $t('lang.QText96'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                class="input48"
                v-model="form2.totalVolume"
                type="number"
                @keydown.native="limite2"
                min="0"
                maxlength="10"
              >
                <template slot="append"
                  ><el-select
                    class="Unit-select"
                    v-model="form2.totalVolumeUnit"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in VolumeUnitListForTotal"
                      :key="item.value"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :disabled="item.disabled"
                    >
                    </el-option> </el-select></template
              ></el-input>
            </el-form-item>
            <el-form-item
              class="form-item unspin"
              prop="totalQuantity"
              :label="$t('lang.QText30')"
              :rules="[
                {
                  required: true,
                  message: $t('lang.SText137'),
                  trigger: 'blur',
                },
                {
                  min: 1,
                  max: 10,
                  message: $t('lang.QText93'),
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                class="input48"
                v-model="form2.totalQuantity"
                type="number"
                @keydown.native="limite"
                min="0"
                maxlength="10"
              >
                <template slot="append"
                  ><el-select
                    class="Unit-select"
                    v-model="form2.totalQuantitUnit"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in CargoUnitListForTotal"
                      :key="item.value"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :disabled="item.disabled"
                    >
                    </el-option> </el-select></template
              ></el-input>
            </el-form-item>
            <div
              class="form-item-title"
              style="margin-top: 30px; padding-top: 30px; margin-bottom: 10px"
            >
              {{ $t("lang.BText52") }}
            </div>
            <div class="OriginServices-checkbox">
              <el-checkbox-group v-model="form2.dangerousGoodsType">
                <el-checkbox class="quotation-detials-checkbox" label="0">
                  <svg-icon icon-class="batteries"></svg-icon>
                  {{ $t("lang.QText32") }}</el-checkbox
                >
                <el-checkbox
                  class="quotation-detials-checkbox HazardousMaterials"
                  label="1"
                >
                  <svg-icon icon-class="HazardousMaterials"></svg-icon
                  >{{ $t("lang.QText33") }}</el-checkbox
                >
                <el-checkbox class="quotation-detials-checkbox" label="2">
                  <svg-icon icon-class="other"></svg-icon
                  >{{ $t("lang.QText34") }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </el-form>

      <el-form v-if="step == 3" :model="form3" :rules="rules" ref="form3">
        <div class="step-title">
          {{ $t("lang.BText79") }}
          <div class="optional">{{ $t("lang.NText181") }}</div>
        </div>
        <div class="main">
          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText48") }}</div>
            <div class="main">
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText49')"
                v-model="form3.originPickUpFlag"
                true-label="1"
                false-label="0"
                :disabled="form0.fromType == 0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.BText25')"
                v-model="form3.originCustomsClearFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText57')"
                v-model="form3.originInsurFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <div class="insurance-line" v-if="form3.originInsurFlag == 1">
                <el-form-item
                  class="form-item"
                  prop="originInsurVlaue"
                  :label="$t('lang.QText58')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText103'),
                    },
                  ]"
                >
                  <el-input
                    class="insurance-input"
                    v-model="form3.originInsurVlaue"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  prop="originInsurCurr"
                  :label="$t('lang.QText59')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText104'),
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-select
                    class="insurance-select"
                    v-model="form3.originInsurCurr"
                    :placeholder="$t('lang.BText80')"
                  >
                    <el-option
                      v-for="item in CurrentyList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!-- 2022/2/9去掉 -->
              <!-- <el-checkbox
                class="quotation-detials-checkbox"
                label="Bond"
                true-label="1"
                false-label="0"
                v-model="form3.originBondFlag"
              ></el-checkbox>
              <div class="bond-radio" v-if="form3.originBondFlag == 1">
                <el-radio v-model="form3.originBondType" label="0">
                  Single bond
                </el-radio>
                <el-radio v-model="form3.originBondType" label="1">
                  Annual bond
                </el-radio>
              </div> -->
              <el-checkbox
                class="quotation-detials-checkbox"
                label="ISF"
                v-model="form3.originIsfFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText61')"
                v-model="form3.originDocFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                label="AMS"
                v-model="form3.originAmsFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                label="ACI"
                v-model="form3.originAciFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText63')"
                v-model="form3.originWareFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="originWareServices"
                  v-if="form3.originWareFlag == 1"
                  :label="$t('lang.NText182')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText183'),
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form3.originWareServices"
                    :placeholder="$t('lang.BText80')"
                  >
                    <el-option
                      v-for="item in WarehouseList.originWarehouse"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText66") }}</div>
            <div class="main">
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText67')"
                v-model="form3.destinationDelivFlag"
                true-label="1"
                false-label="0"
                :disabled="form0.toType == 0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.BText25')"
                v-model="form3.destinationCustomsClearFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText69')"
                v-model="form3.destinationBondFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <el-form-item
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText110'),
                    trigger: ['blur', 'change'],
                  },
                ]"
                class="bond-radio"
                v-if="form3.destinationBondFlag == 1"
                prop="destinationBondType"
              >
                <el-radio v-model="form3.destinationBondType" label="0">
                  {{ $t("lang.QText70") }}
                </el-radio>
                <el-radio v-model="form3.destinationBondType" label="1">
                  {{ $t("lang.QText71") }}
                </el-radio>
              </el-form-item>
              <el-checkbox
                class="quotation-detials-checkbox"
                :label="$t('lang.QText63')"
                v-model="form3.destinationWareFlag"
                true-label="1"
                false-label="0"
              ></el-checkbox>
              <div class="form-line">
                <el-form-item
                  class="form-item"
                  prop="destinationWareServices"
                  v-if="form3.destinationWareFlag == 1"
                  :label="$t('lang.NText182')"
                  :rules="[
                    {
                      required: true,
                      message: $t('lang.NText183'),
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-select
                    class="date-card-select"
                    v-model="form3.destinationWareServices"
                    :placeholder="$t('lang.BText80')"
                  >
                    <el-option
                      v-for="item in WarehouseList.destWarehouse"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="dw-card2">
            <div class="title">{{ $t("lang.QText38") }}</div>
            <div class="main incotermOne">
              <!-- <el-form-item
                class="form-item incotermsType"
                prop="incotermsType"
                :rules="[
                  {
                    required: true,
                    message: 'Please select incotermsType',
                    trigger: 'blur',
                  },
                ]"
                label="I am a"
              > -->
              <el-form-item
                class="form-item"
                prop="incotermsType"
                :label="$t('lang.QText39')"
              >
                <div class="radiobox">
                  <div
                    class="option"
                    :class="{ optionFocus: form3.incotermsType == 0 }"
                    @click="clickType(0)"
                  >
                    {{ $t("lang.QText40") }}
                  </div>
                  <div
                    class="option"
                    :class="{ optionFocus: form3.incotermsType == 1 }"
                    @click="clickType(1)"
                  >
                    {{ $t("lang.QText41") }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                class="form-item radio Incoterms-radio incotermsType"
                prop="incotermsDataType"
                v-if="form3.incotermsType == 0 || form3.incotermsType == 1"
                label=" "
                :rules="[
                  {
                    required: true,
                    message: $t('lang.NText98'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio-group v-model="form3.incotermsDataType">
                  <template v-if="form3.incotermsType == 0">
                    <el-radio
                      :label="item.dictValue"
                      v-for="item in incotermsData.buyData"
                      :key="item.dictValue"
                      >{{ item.dictLabel }}</el-radio
                    ></template
                  >
                  <template v-if="form3.incotermsType == 1">
                    <el-radio
                      :label="item.dictValue"
                      v-for="item in incotermsData.sellerData"
                      :key="item.dictValue"
                      >{{ item.dictLabel }}</el-radio
                    ></template
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <!-- <div class="dw-card2">
              <div class="title">Remark</div>
              <div class="main">
                <el-form-item class="form-item" prop="item13">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="Yo! Leave a message, or something..."
                    v-model="form3.item1"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div> -->
        </div>
      </el-form>

      <div class="bottom-button">
        <div class="form-button">
          <el-button type="info" v-if="step > 0" @click="Previous()">{{
            $t("lang.BText97")
          }}</el-button>
          <el-button type="primary" v-if="step < 3" @click="Next()">{{
            $t("lang.QText19")
          }}</el-button>
          <el-button type="primary" v-if="step == 3" @click="Review()">{{
            $t("lang.QText76")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dwTimeLine from "../../../components/dwTimeline/dwTimeLine.vue";
import Template from "../../Booking/template.vue";
import utils from "../../../utils/util";
export default {
  components: {
    dwTimeLine,
    Template,
  },
  props: {
    quotationMainType: {
      type: String,
      default: "",
    },
    quotationType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      recentlyData:[],
      recentlyDataDe:[],
      form0: {
        toType: "0",
        fromType: "0",
      },
      form1: {},
      form2: {
        dangerousGoodsType: [],
        weightList: [{ count: "1", containerSize: "" }],
      },
      form3: {
        originPickUpFlag: 0,
        originCustomsClearFlag: 0,
        originInsurFlag: 0,
        originBondFlag: 0,
        originIsfFlag: 0,
        originWareFlag: 0,
        destinationDelivFlag: 0,
        destinationCustomsClearFlag: 0,
        destinationBondFlag: 0,
        destinationWareFlag: 0,
      },
      submitForm: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      deliveryDayOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 86400000 * 2;
        },
      },
      stepList: [
        {
          name: "From - To",
          zh_name: "起始-到达",
        },
        {
          name: "Date",
          zh_name: "日期",
        },
        {
          name: "Cargo info",
          zh_name: "货物信息",
        },
        {
          name: "Services",
          zh_name: "服务",
        },
      ],
      groupList: [{ id: 1, poDetailType: "0", item7: "Pallet" }],
      PortsList: [],
      rules: {},
      step: 0,
      option: 0,
      ContainerTypeList: [], //箱型下拉数据
      CargoTypeList: [], //货物类型下拉
      WeightUnitList: [], //重量单位
      VolumeUnitList: [], //体积单位
      VolumeUnitListForTotal: [], //总计单位体积
      CargoUnitListForTotal: [], //总计计量单位
      CargoUnitList: [], //计货单位
      WarehouseList: [], //仓库下拉
      CurrentyList: [], //询价单币种
      incotermsData: [], //贸易条款
      checkList: false,
      PackageDetail: false,
      selectLoading: false,
      countryList: [],
      fromDoorStateList: [],
      fromDoorCityList: [],
      toDoorStateList: [],
      toDoorCityList: [],
      originPickUpStateList: [],
      originPickUpCityList: [],
      destinationDelivStateList: [],
      destinationDelivCityList: [],
      fromDoorStateLoading: false,
      fromDoorCityLoading: false,
      toDoorStateLoading: false,
      toDoorCityLoading: false,
      originPickUpStateLoading: false,
      originPickUpCityLoading: false,
      destinationDelivStateLoading: false,
      destinationDelivCityLoading: false,
      userInfo: {},
    };
  },
  created() {
    this.setRules();
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
    this.init();
    console.log(this.quotationType);
  },
  methods: {
    chooseRecently(val){
      var address=val.address?val.address:'';
      this.$set(this.form0,'fromDoorAddress',address);
      this.$set(this.form0,'fromDoorCountry',val.country);
      this.$set(this.form0,'fromDoorState',val.state);
      this.$set(this.form0,'fromDoorCity',val.city);
      this.$set(this.form0,'fromDoorZipCode',val.zipCode);
      this.getStateList({ code: val.country },0);
      this.getCityList({ state: val.state, code: val.country },0);
    },
    chooseRecentlyDe(val){
      var address=val.address?val.address:'';
      this.$set(this.form0,'toDoorAddress',address);
      this.$set(this.form0,'toDoorCountry',val.country);
      this.$set(this.form0,'toDoorState',val.state);
      this.$set(this.form0,'toDoorCity',val.city);
      this.$set(this.form0,'toDoorZipCode',val.zipCode);
      this.getStateList({ code: val.country },1);
      this.getCityList({ state: val.state, code: val.country },1);
    },
    init() {
      this.getBaseData();
      this.$session("countryList").get((value) => {
        this.countryList = value;
      });
      this.findPortsList();
      this.findaddressList();
      this.findaddressListDe();
    },
    
    findaddressList() {
      var queryForm={
        type:'10',
        addressType: '10',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyData=res.data.data||[]
        });
    },
    findaddressListDe() {
      var queryForm={
        type:'10',
        addressType: '20',
        companyId:this.userInfo.companyid
      }
      this.$ajax
        .cPost(this.source.address_api.fill, queryForm)
        .then((res) => {
          this.recentlyDataDe=res.data.data||[]
        });
    },
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    limite2(e) {
      let key = e.key;
      if (key === "e") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    changeFormType(params) {
      this.$set(this.form3, "originPickUpFlag", params);
    },
    changeToType(params) {
      this.$set(this.form3, "destinationDelivFlag", params);
    },
    //获取基础数据
    getBaseData() {
      let that = this;
      this.$session("ContainerTypeList").get((value) => {
        that.ContainerTypeList = value;
      });
      this.$session("CargoTypeList").get((value) => {
        that.CargoTypeList = value;
      });
      this.$session("WeightUnitList").get((value) => {
        that.WeightUnitList = value;
        that.$set(that.groupList[0], "poWeightUnit", value[0].dictValue);
        that.$set(that.form2, "totalWeightUnit", value[0].dictValue);
      });
      this.$session("VolumeUnitList").get((value) => {
        that.VolumeUnitList = value;
        that.$set(that.groupList[0], "poVolumeUnit", value[0].dictValue);
      });
      this.$session("CargoUnitList").get((value) => {
        that.CargoUnitList = value;
        that.$set(that.groupList[0], "poQtyUnit", value[0].dictValue);
      });
      this.$session("VolumeUnitListForTotal").get((value) => {
        that.VolumeUnitListForTotal = value;
        that.$set(that.form2, "totalVolumeUnit", value[0].dictValue);
      });

      this.$session("CargoUnitListForTotal").get((value) => {
        that.CargoUnitListForTotal = value;
        that.$set(that.form2, "totalQuantitUnit", value[0].dictValue);
      });
      this.$session("WarehouseList").get((value) => {
        that.WarehouseList = value;
      });
      this.$session("CurrentyList").get((value) => {
        that.CurrentyList = value;
      });
      this.$session("incotermsData").get((value) => {
        that.incotermsData = value;
      });
      this.$session("CommodityType").get((value) => {
        that.CommodityType = value;
      });
    },
    getStateList(data, num) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getStateList, data)
        .then((res) => {
          this.fromDoorStateLoading = false;
          this.toDoorStateLoading = false;
          if (res.data.result) {
            if (num == 0) {
              this.fromDoorStateList = res.data.data;
            } else if (num == 1) {
              this.toDoorStateList = res.data.data;
            } else if (num == 2) {
              this.originPickUpStateList = res.data.data;
            } else if (num == 3) {
              this.destinationDelivStateList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getCityList(data, num) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getCityList, data)
        .then((res) => {
          this.fromDoorCityLoading = false;
          this.toDoorCityLoading = false;
          this.originPickUpCityLoading = false;
          this.destinationDelivCityLoading = false;
          if (res.data.result) {
            if (num == 0) {
              this.fromDoorCityList = res.data.data;
            } else if (num == 1) {
              this.toDoorCityList = res.data.data;
            } else if (num == 2) {
              this.originPickUpCityList = res.data.data;
            } else if (num == 3) {
              this.destinationDelivCityList = res.data.data;
            }
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    countryChange(e, num) {
      if (num == 0) {
        this.$set(this.form0, "fromDoorState", "");
        this.$set(this.form0, "fromDoorCity", "");
      } else if (num == 1) {
        this.$set(this.form0, "toDoorState", "");
        this.$set(this.form0, "toDoorCity", "");
      } else if (num == 2) {
        this.$set(this.form3, "originPickUpState", "");
        this.$set(this.form3, "originPickUpCity", "");
      } else if (num == 3) {
        this.$set(this.form3, "destinationDelivState", "");
        this.$set(this.form3, "destinationDelivCity", "");
      }
      if (e) {
        this.fromDoorStateLoading = true;
        this.getStateList({ code: e }, num);
      }
    },
    stateChange(e, num) {
      if (num == 0) {
        this.$set(this.form0, "fromDoorCity", "");
        if (e) {
          this.fromDoorCityLoading = true;
          this.getCityList({ state: e, code: this.form0.fromDoorCountry }, num);
        }
      } else if (num == 1) {
        this.$set(this.form0, "toDoorCity", "");
        if (e) {
          this.toDoorCityLoading = true;
          this.getCityList({ state: e, code: this.form0.toDoorCountry }, num);
        }
      } else if (num == 2) {
        this.$set(this.form3, "originPickUpCity", "");
        if (e) {
          this.originPickUpCityLoading = true;
          this.getCityList(
            { state: e, code: this.form3.originPickUpCountry },
            num
          );
        }
      } else if (num == 3) {
        this.$set(this.form3, "destinationDelivCity", "");
        if (e) {
          this.destinationDelivCityLoading = true;
          this.getCityList(
            { state: e, code: this.form3.destinationdelivCountry },
            num
          );
        }
      }
    },
    findPortsList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.quotation_api.findPortsList, {
          dictLabel: e,
        })
        .then((res) => {
          console.log(res);
          this.selectLoading = false;
          if ((res.resultCode = "success")) {
            this.PortsList = res.data.data;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    setRules() {
      this.rules1 = {
        item1: [
          {
            required: true,
            message: this.$t("lang.NText185"),
            trigger: "change",
          },
        ],
      };
    },
    disabledCargoReadyDate(date) {
      console.log(date);
    },
    Previous() {
      this.step--;
    },
    setLabel() {
      if (this.step == 0) {
        if (this.form0.fromType == 0) {
          this.form0.fromCyPortName = this.$refs.fromCyPort.selectedLabel;
        } else {
          // this.$set(this.form3, "originPickUpFlag", true);
          // this.$set(
          //   this.form3,
          //   "originPickUpCountry",
          //   this.form0.fromDoorCountry
          // );
          // this.$set(this.form3, "originPickUpState", this.form0.fromDoorState);
          // this.$set(this.form3, "originPickUpCity", this.form0.fromDoorCity);
          // this.$set(
          //   this.form3,
          //   "originPickUpAddress",
          //   this.form0.fromDoorAddress
          // );
          // this.$set(
          //   this.form3,
          //   "originPickUpZipCode",
          //   this.form0.fromDoorZipCode
          // );
          this.originPickUpStateList = this.fromDoorStateList;
          this.originPickUpCityList = this.fromDoorCityList;
          this.form0.fromDoorCountryName =
            this.$refs.fromDoorCountry.selectedLabel;
          this.form0.fromDoorStateName = this.$refs.fromDoorState.selectedLabel;
          this.form0.fromDoorCityName = this.$refs.fromDoorCity.selectedLabel;
        }
        if (this.form0.toType == 0) {
          this.form0.toCyPortName = this.$refs.toCyPort.selectedLabel;
        } else {
          // this.$set(this.form3, "destinationDelivFlag", true);
          // this.$set(
          //   this.form3,
          //   "destinationdelivCountry",
          //   this.form0.toDoorCountry
          // );
          // this.$set(
          //   this.form3,
          //   "destinationDelivState",
          //   this.form0.toDoorState
          // );
          // this.$set(this.form3, "destinationDelivCity", this.form0.toDoorCity);
          // this.$set(
          //   this.form3,
          //   "destinationDelivAddress",
          //   this.form0.toDoorAddress
          // );
          // this.$set(
          //   this.form3,
          //   "destinationDelivZipCode",
          //   this.form0.toDoorZipCode
          // );
          this.destinationDelivStateList = this.toDoorStateList;
          this.destinationDelivCityList = this.toDoorCityList;

          this.form0.toDoorCountryLabel =
            this.$refs.toDoorCountry.selectedLabel;
          this.form0.toDoorStateName = this.$refs.toDoorState.selectedLabel;
          this.form0.toDoorCityName = this.$refs.toDoorCity.selectedLabel;
        }
      }
      if (this.step == 2) {
        if (this.form2.cargoType) {
          this.form2.cargoTypeName = this.$refs.cargoType.selectedLabel;
        }
        if (this.form2.commodityHscodes) {
          this.form2.commodityHscodesName =
            this.$refs.commodityHscodes.selectedLabel;
        }
      }

      // if (this.step == 3) {
      //   if (this.form3.originPickUpFlag == 1) {
      //     this.form3.originPickUpCountryName =
      //       this.$refs.originPickUpCountry.selectedLabel;
      //     this.form3.originPickUpStateName =
      //       this.$refs.originPickUpState.selectedLabel;
      //     this.form3.originPickUpCityName =
      //       this.$refs.originPickUpCity.selectedLabel;
      //   }
      //   if (this.form3.destinationDelivFlag == 1) {
      //     this.form3.destinationdelivCountryName =
      //       this.$refs.destinationdelivCountry.selectedLabel;
      //     this.form3.destinationDelivStateName =
      //       this.$refs.destinationDelivState.selectedLabel;
      //     this.form3.destinationDelivCityName =
      //       this.$refs.destinationDelivCity.selectedLabel;
      //   }
      // }
    },
    Next() {
      this.$refs["form" + this.step].validate((valid) => {
        if (valid) {
          if (this.step == 2) {
            var aa = 1;
            if (
              this.form2.totalWeight < 0 ||
              this.form2.totalVolume < 0 ||
              this.form2.totalQuantity < 0
            ) {
              this.$notify.error({
                title: this.$t("lang.SText134"),
                offset: 100,
                duration: 3000,
              });
              aa = 0;
            }
            this.form2.containerDatas = this.form2.weightList;
            if (aa == 1) {
              this.setLabel();
              this.step++;
              this.$emit("next");
            }
          } else {
            this.setLabel();
            this.step++;
            this.$emit("next");
          }
        } else {
          return false;
        }
      });
    },
    clickType(pro) {
      this.$set(this.form3, "incotermsType", pro);
      this.$set(this.form3, "incotermsDataType", "");
    },
    removeDomain(data) {
      var index = this.form2.weightList.indexOf(data);
      this.ContainerTypeList.forEach((item, index) => {
        if (item.dictValue == data.containerSize) {
          item.disabled = false;
        }
      });
      if (index !== -1) {
        this.form2.weightList.splice(index, 1);
      }
    },
    addDomain() {
      this.form2.weightList.push({
        count: "1",
        containerSize: "",
      });
    },
    Review() {
      this.$refs.form3.validate((valid) => {
        if (valid) {
          // this.setLabel();
          // this.form3.packingList = JSON.stringify(this.groupList);
          this.submitForm = {
            ...{},
            ...this.form0,
            ...this.form1,
            ...this.form2,
            ...this.form3,
          };
          this.submitForm.quotationMainType = this.quotationMainType;
          this.submitForm.quotationType = this.quotationType;
          // if (this.$route.params.quotataionId) {
          //   this.submitForm.quotataionId = this.quotataionId;
          // }
          // if (this.$i18n.locale == "en_us") {
          //   this.submitForm.deliveryDate = utils.zhDate_to_enDate(
          //     this.submitForm.deliveryDate
          //   );
          //   this.submitForm.cargoReadyDate = utils.zhDate_to_enDate(
          //     this.submitForm.cargoReadyDate
          //   );
          // }
          this.$emit("submit", { submitForm: this.submitForm });
          window.scrollTo(0, 0);
        } else {
          return false;
        }
      });
    },
    // CargoReadyDay改变时，设置deliveryDay可选范围
    CargoReadyDayChange() {
      let that = this;
      this.deliveryDayOptions = {
        disabledDate(time) {
          if (that.form1.cargoReadyDate) {
            return (
              time.getTime() < new Date(that.form1.cargoReadyDate).getTime()
            );
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    deliveryDayChange() {
      let that = this;
      this.pickerOptions = {
        disabledDate(time) {
          if (that.form1.deliveryDate) {
            return (
              time.getTime() > new Date(that.form1.deliveryDate).getTime() ||
              time.getTime() < Date.now() + 86400000 * 2
            );
          } else {
            return time.getTime() < Date.now() + 86400000 * 2;
          }
        },
      };
    },
    //重量选择改变时改变不可选状态
    weightSelectChange(val) {
      this.ContainerTypeList.forEach((item) => {
        item.disabled = false;
        this.form2.weightList.forEach((item2) => {
          if (item.dictValue == item2.containerSize) {
            item.disabled = true;
          }
        });
      });
    },
    addNewGroup() {
      this.groupList.push({ id: "", item7: "Pallet", Dimensions: "1" });
    },
    delectGroup(index) {
      if (this.groupList.length > 1) {
        this.groupList.splice(index, 1);
      }
    },
    Add(e) {
      this.form2.weightList.forEach((item, index) => {
        if (e == index) {
          if (item.count < 99) {
            item.count++;
          }
        }
      });
    },
    Prep(e) {
      this.form2.weightList.forEach((item, index) => {
        if (e == index) {
          if (item.count > 1) {
            item.count--;
          }
        }
      });
    },
    openCheckBox() {
      this.showDelect = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.create {
  text-align: left;
  padding-bottom: 100px;
  .el-input__inner {
    height: 48px;
  }

  .el-input {
    height: 48px;
  }

  .el-form-item {
    display: flex;
    flex-direction: column;
  }
  .date-card-select {
    width: 100%;
  }
  .packingList {
    background-color: $background1;
    padding: 20px;
    .group {
      padding-bottom: 20px;
      border-bottom: 1px solid $borderColor1;
      padding-top: 10px;
    }
    .group-title {
      color: $fontColor2;
      margin-bottom: 20px;
    }
    .group-main {
      .group-line {
        display: flex;
        margin: 15px 0;
        .Dimensions-input {
          display: flex;
          margin-right: 20px;
        }
        .Lenght-title {
          display: flex;
          line-height: 30px;
        }
        .Lenght-title1,
        .Lenght-title2 {
          width: 90px;
        }
        .Lenght-title3 {
          width: 220px;
        }
        .Lenght-title5 {
          width: 300px;
        }
        .QtyType {
          font-size: 14px;
          margin: 0 5px;
        }
        .group-item {
          .group-item-title {
            color: $fontColor2;
            margin-bottom: 10px;
          }

          .width100 {
            width: 100px;
          }
        }
      }
    }

    .delect {
      color: $background2;
      border: 1px solid $borderColor1;
      width: 47px;
      line-height: 36px;
      padding-bottom: 10px;
      text-align: center;
      background-color: #fff;
      margin-top: -65px;
      float: right;
    }
    .addButton {
      height: 48px;
      width: 180px;
      line-height: 48px;
      color: $background2;
      border: 1px solid $borderColor1;
      margin-top: 20px;
      text-align: center;
      background-color: #fff;
      font-size: 20px;
      font-family: "montserratLight";
      cursor: pointer;
    }
  }
  .addIcon {
    font-size: 30px;
    font-weight: 100;
    font-family: "montserratLight";
    margin-right: 10px;
    line-height: 20px;
    border: 1px solid $background2;
    width: 20px;
    text-align: center;
  }
  .timeline {
    background-color: $background1;
    padding: 20px;
    border: 1px solid $borderColor1;
    .dwTimeLine {
      margin: auto;
      width: 900px;
    }
  }
  .stepDiv {
    margin: auto;
    width: 900px;
    position: relative;
    padding-top: 20px;
    .step {
      color: $background2;
      position: absolute;
      left: -120px;
      top: 35px;
    }
    .skip {
      font-size: 20px;
      color: $background2;
      cursor: pointer;
      position: absolute;
      border: 2px solid $background2;
      right: 20px;
      top: 35px;
      width: 120px;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
    .step-title {
      font-size: 30px;
      color: $mainColor;
      text-align: left;
      padding: 10px 0;
      display: flex;
      font-family: "montserratLight";
      .optional {
        font-size: 20px;
        color: #b3b3b3;
        line-height: 40px;
        margin-left: 20px;
      }
    }
  }

  .dw-card2 {
    .main {
      display: flex;
      flex-direction: column;
    }
  }

  .radio {
    font-size: 18px;
    margin-left: 10px;
  }

  .form-line {
    display: flex;
    justify-content: space-between;
    .form-item {
      width: 48%;
    }
  }

  .Date-line {
    display: flex;
    justify-content: space-between;
  }

  .bottom-button {
    display: flex;
    margin-top: 50px;
  }

  .step1-select {
    width: 48%;
  }

  .date-card {
    width: 48%;
    .date-card-select {
      width: 100%;
    }
  }

  .input48 {
    width: 48%;
  }

  // .Incoterms-radio {
  //   margin-left: 60px;
  // }

  .el-date-editor {
    width: 100%;
  }

  .Unit-select {
    width: 120px;
  }

  .weightItem {
    .el-form-item__content {
      display: flex;
      .weight-select {
        width: 200px;
      }
      .el-button--text {
        font-size: 20px;
        color: $background2;
      }
      .weightInput {
        width: 140px;
        margin-left: -1px;
        margin-right: 20px;
        .chenghao {
          line-height: 48px;
          font-size: 20px;
          padding-left: 5px;
          color: $mainColor;
        }
      }
    }
    .add {
      position: absolute;
      left: 309px;
      top: 0;
      width: 30px;
      height: 24px;
      color: $fontColor1;
      background-color: $borderColor1;
      .svg-icon {
        position: absolute;
        height: 10px;
        left: 8px;
        top: 5px;
        line-height: 10px;
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
    .add:hover,
    .prep:hover {
      background-color: $fontColor3;
    }
    .prep {
      position: absolute;
      left: 309px;
      top: 24px;
      width: 30px;
      height: 24px;
      color: $fontColor1;
      background-color: $borderColor1;
      .svg-icon {
        position: absolute;
        height: 25px;
        left: 8px;
        top: 2px;
        line-height: 10px;
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
  }

  .OriginServices-checkbox {
    display: flex;
    flex-direction: column;
    .svg-icon {
      margin-right: 10px;
    }
    .HazardousMaterials {
      .svg-icon {
        stroke: none;
      }
    }
  }
  .quotation-detials-checkbox {
    display: flex;
    .el-checkbox__label {
      font-size: 20px !important;
      color: $mainColor;
      padding: 10px 20px;
      .svg-icon {
        margin-top: -10px;
      }
    }
  }
  .Package-details {
    display: flex;
    .add {
    }
  }

  .HazardousMaterials {
    .svg-icon {
      stroke: none;
    }
  }

  .ContainersTitle {
    color: $mainColor;
  }

  .insurance-line {
    display: flex;
    .insurance-input {
      width: 250px;
      margin-right: 20px;
    }
    .insurance-select {
      width: 135px;
    }
  }
  .bond-radio {
    display: flex;
    margin-bottom: 30px;
  }

  .right-button {
    cursor: pointer;
    .add {
      height: 20px;
      line-height: 18px;
      width: 20px;
      text-align: center;
      border: 1px solid $background2;
      margin-right: 10px;
    }
  }
  .incotermsType {
    display: flex;
    flex-direction: row;
  }
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}
</style>