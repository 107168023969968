<template>
  <div
    class="dwTimeLine"
    :class="{ type1: styleType == 1, type2: styleType == 2 }"
  >
    <div
      class="step"
      :class="{
        left: index < status,
        now: index == status,
        last: index == data.length - 1,
      }"
      v-for="(item, index) in data"
      :key="index"
    >
      <div class="name" v-if="$i18n.locale == 'zh_cn'">{{ item.zh_name }}</div>
      <div class="name" v-else>{{ item.name }}</div>
      <div class="text" v-if="item.text">{{ item.text }}</div>
      <div class="step-line"></div>
      <div class="step-icon">{{ index + 1 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    status: {
      type: Number,
      default: 0,
    },
    styleType: {
      type: String,
      default: "0",
    },
  },
};
</script>

<style lang='scss'>
@import "../../assets/base.scss";
.dwTimeLine {
  display: flex;
  .step {
    position: relative;
    min-width: 200px;
    height: 110px;
    .name {
      font-size: 20px;
      text-align: left;
      line-height: 24px;
      color: $fontColor1;
      min-width: 0;
      width: 250px;
      word-break: normal;
    }

    .step-line {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 25px;
      border-bottom: 4px dashed $fontColor1;
    }
    .step-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 42px;
      width: 42px;
      background-color: $fontColor1;
      border: 4px solid $fontColor1;
      line-height: 42px;
      text-align: center;
      color: #fff;
      font-size: 30px;
    }
  }
  .left {
    .name,.text {
      color: $mainColor;
    }
    .step-line {
      border-bottom: 4px solid $mainColor;
    }
    .step-icon {
      color: $mainColor;
      background-color: #fff;
      border: 4px solid $mainColor;
    }
  }

  .now {
    .name,.text {
      color: $background2;
    }
    .step-icon {
      background-color: $background2;
      border: 4px solid $background2;
    }
  }

  .last {
    .step-line {
      display: none;
    }
  }
}

.type1 {
  .name {
    font-size: 14px !important;
  }
  .text {
    font-size: 18px !important;
    font-family: 'montserratLight';
  }
}
</style>